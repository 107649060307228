<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                 <v-breadcrumbs :items="breadcumbs"></v-breadcrumbs>
                 <template v-slot:item="{ item }">
                 <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled" exact>
                 {{ item.text }}
                 </v-breadcrumbs-item>
                 </template>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-progress-linear
                        :active="loading" :indeterminate="loading" absolute bottom color="deep-purple accent-4">
                    </v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  solo clearable v-model="office" :items="offices" item-value="office_id" item-text="office" label="MILL ID" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                             <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete  solo clearable v-model="vendor_name" :items="departments" item-value="vendor_name" :item-text="item => item.vendor_id +' - '+ item.vendor_name" label="VENDOR" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                 <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" clearable readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                     </v-menu>
                            </v-col>
                             <!-- <v-col cols="12" xs="12" sm="5" md="1">
                                <v-autocomplete  solo clearable v-model="approve" :items="approves" item-value="value" item-text="descr" label="APROVED" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col> -->
                             <v-col cols="12" xs="12" sm="5" md="1">
                                <v-autocomplete  solo clearable v-model="status" :items="statuses" item-value="value" item-text="status" label="STATUS" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                             <!-- <v-col cols="12" xs="12" sm="5" md="1">
                                <v-autocomplete  solo clearable v-model="raw" :items="raws" item-value="value" item-text="raw" label="RAW" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col>
                             <!-- <v-col cols="12" xs="12" sm="5" md="1">
                                <v-btn class="mr-2" color="error" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
          <v-col cols="12" style="padding-bottom: 100px">
                 <!-- <v-card v-show="display == 'none' ? false: true"> -->
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                       <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search PO ID"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                              
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card> 
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Purchase Order Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [{
                office_id : 'Sunrise Mill',
                office : 'Sunrise Mill'
            }],
            office: 'Sunrise Steel',
            departments: [],
            department: '',
            status: '',
            vendor_name:'',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                {
                    text: 'Invoice',
                    align: 'start',
                    sortable: true,
                    value: 'dt_po',
                },
                { text: 'PO ID', value: 'po_id' },
                { text: 'Vendor Name', value: 'vendor_name' },
                { text: 'Proud Code', value: 'prod_code' },
                { text: 'Descr', value: 'descr',height: "500%" },
               // { text: 'Item Desc', value: 'item_desc1' },
                { text: 'PO Item', value: 'po_item' },
                { text: 'Stat', value: 'stat' },
                { text: 'Qty', value: 'qty' },
                { text: 'Weight', value: 'wgt' },
                { text: 'Unit.Meas', value: 'unit_meas' },
                { text: 'Unit.Price', value: 'unit_price' },
                { text: 'Curr', value: 'curr_id' },
                { text: 'Amt.Net', value: 'amt_net' },
                { text: 'Qty.Rcv', value: 'qty_recive' },
                { text: 'Wgt.Rcv', value: 'wgt_recive' },
                // { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
        }
    },
    mounted(){
        this.getEnvConf()
        this.updateDepartment()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPR1') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve1 = res.data.data[i]['var_value']
                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVPR2') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve2 = res.data.data[i]['var_value']
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'RAW') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.raw = res.data.data[i]['var_value']
                            this.disabled_search = true
                        }
                    }

                }

            })
        },
        search(){
          
            this.getPullData(1, 10, true)
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/sm/po?po_id=${this.$store.state.text ? this.$store.state.text : ''}&vendor_name=${this.vendor_name ? this.vendor_name : ""}&txtStatus=${this.status ? this.status : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(this.state.text)
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        updateDepartment(){
            // this.loading2 = true
            axios.get(`${process.env.VUE_APP_URL}/api/master/sm/vendor`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data)
                this.departments = res.data
                this.loading2 = false
            });
        },
        
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        }
    }
}
</script>